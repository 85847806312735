import React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";

export const BlockButton = styled.button<{ active?: boolean }>`
  background-color: ${({ active }) => (active ? "#ce0000" : "#1ca05a")};
  border-color: #eeeeee;
  color: #ffffff;
  border: 0;
  white-space: nowrap !important;

  padding: 18px 28px;
  font-size: 22px;
  border-radius: 8px;

  width: 100%;
  margin-bottom: 0.5em;

  :hover,
  :active,
  :focus {
    background-color: #ce0000;
  }
`;

export const Button = styled.button<{ active?: boolean }>`
  background-color: ${({ active }) => (active ? "#ce0000" : "#1ca05a")};
  border-color: #eeeeee;
  color: #ffffff;
  border: 0;
  white-space: nowrap !important;

  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;

  :hover,
  :active,
  :focus {
    background-color: #ce0000;
  }

  :disabled {
    background-color: #cccccc;
  }
`;

export const SmallButton = styled.button<{
  active?: boolean;
  noBorderRadius?: boolean;
}>`
  background-color: ${({ active }) => (active ? "#ce0000" : "#1ca05a")};
  border-color: #eeeeee;
  color: #ffffff;
  border: 0;
  white-space: nowrap !important;

  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  ${({ noBorderRadius }) => (noBorderRadius ? "" : "border-radius: 0.3rem;")}

  :hover,
  :active,
  :focus {
    background-color: #ce0000;
  }

  :disabled {
    background-color: #cccccc;
  }
`;

export const FlashingButton: React.FC<{ children: typeof Button }> = ({
  children,
}) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    let interval;
    const timeout = setTimeout(() => {
      interval = setInterval(() => {
        setIsActive((prev) => !prev);
      }, 2000);
    }, 10000);

    return () => {
      if (timeout) clearTimeout(timeout);
      if (interval) clearInterval(interval);
    };
  }, []);

  return React.cloneElement(children, { active: isActive });
};
