import React from "react";
import { render } from "react-dom";

import { App } from "./App";
import { Store } from "./context/store";

const Application: React.FC<{}> = () => {
  return (
    <Store>
      <App />
    </Store>
  );
};

render(<Application />, document.getElementById("root"));
