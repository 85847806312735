import React from "react";
import { Col, Row } from "react-bootstrap";

export const Footer = () => (
  <Row style={{ backgroundColor: "rgb(243, 240, 235)" }}>
    <Col
      style={{
        padding: 20,
        margin: 0,
      }}
    >
      <p>
        The Islamic Human Rights Commission Trust was set up to support the
        charitable works of The Islamic Human Rights Commission Ltd. The two
        organisations are separate entities that cooperate to further charitable
        causes in the UK and abroad.
      </p>
      <p>
        This online donation service is provided through the Islamic Human
        Rights Commission Trust (A charity registered in the UK number 1106120)
        and as such any money you donate will go towards projects that are
        purely charitable under English charities laws. For more information on
        what type of work this is, please visit:{" "}
        <a href="">
          Central Register of Charities maintained by the Charity Commission for
          England and Wales (Islamic Human Rights Commission Trust No. 1106120)
        </a>
      </p>
    </Col>
  </Row>
);
