import { DonationDto } from "./model";
import { apiBaseUrl } from "./config";

export interface ApiPriceHandle {
  Amount: number;
  Description: string;
  Group?: string;
  Id: number;
  IsMonthly: boolean;
}

export interface ApiDonationType {
  Details: {
    AdditionalContent?: string;
    Content?: string;
    Id: number;
    Size: 3 | 6 | 12; // expand
    Type: 1 | 2 | 3; // 1 = text, 2 = image, 3 = video
  }[];
  DisplayOrder?: number;
  DonationPrompt?: string;
  End?: string; // "/Date(1596409200000)/"
  EndMessage?: string;
  Id: number;
  ImageFocus?: "CenterCenter"; // expand
  ImagePath?: string;
  IsActive: boolean;
  IsPublic: boolean;
  IsWebAppDonationType: boolean;
  IsWebShopDonationType: boolean;
  Name: string;
  PriceHandles: ApiPriceHandle[];
  IsLimitedToPriceHandles: boolean;
  Start?: string;
  Summary: string;
  // UrlPath: "General"
}

export interface ApiDonationTypes {
  donationTypeUrlPathStrings: {
    donationTypeId: number;
    urlPathStrings: string[];
  }[];

  donationTypes: ApiDonationType[];
}

export const getApiDonationTypes = async (): Promise<ApiDonationTypes> => {
  const apiDonationTypes = (await (
    await fetch(`${apiBaseUrl}/web/webdonationtypes`)
  ).json()) as ApiDonationTypes; // todo change the endpoint

  return apiDonationTypes;
};

export const postDonation = async (dto: DonationDto) => {
  const formDto = {
    source: dto.source.toString(),

    isMonthly: dto.isMonthly.toString(),
    isGiftaid: dto.isGiftaid.toString(),
    paymentMethod: dto.paymentMethod.toString(),

    "donor[firstName]": dto.donor.firstName,
    "donor[lastName]": dto.donor.lastName,
    "donor[emailAddress]": dto.donor.emailAddress,
    "donor[telephone]": dto.donor.telephone,
    "donor[address]": dto.donor.address,
    ...(dto.donor.address2 ? { "donor[address2]": dto.donor.address2 } : {}),
    "donor[city]": dto.donor.city,
    "donor[postCode]": dto.donor.postCode,
    "donor[country]": dto.donor.country,
    ...(dto.donor.state
      ? {
          "donor[state]": dto.donor.state,
        }
      : {}),
    "donor[emailOptIn]": dto.donor.emailOptIn.toString(),

    ...dto.donationAmounts.reduce(
      (acc, { donationTypeId, amount, donationTypeGroup, intent }, index) => ({
        ...acc,

        [`donationAmounts[${index}][donationTypeId]`]: donationTypeId,
        [`donationAmounts[${index}][amount]`]: amount,
        ...(donationTypeGroup
          ? {
              [`donationAmounts[${index}][donationTypeGroup]`]:
                donationTypeGroup,
            }
          : {}),
        [`donationAmounts[${index}][intent]`]: intent,
      }),
      {}
    ),
  };

  const res = await fetch(`${apiBaseUrl}/api/Donations`, {
    method: "POST",
    // headers: { "content-type": "application/json;charset=utf-8" },
    body: new URLSearchParams(formDto),
    // mode: "no-cors",
    // credentials: "include",
  });

  return (await res.json()).map(({ Id }) => Id) as number[];
};
