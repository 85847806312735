import { ApiDonationTypes } from "~apiAdapter";
import React, { createContext, Dispatch, useMemo, useReducer } from "react";

import { Donation } from "../model";
import { Actions, reducer } from "./reducer";

export interface State {
  apiDonationTypes?: ApiDonationTypes;
  selectedDonationTypeId?: number;
  donation: Donation;
}

const initialState: State = {
  donation: {
    country: "GB",
    donationAmounts: [],
  },
};

export type AsyncDispatch<T> = Dispatch<T | Promise<T>>;

function wrapAsync<T>(dispatch: Dispatch<T>): AsyncDispatch<T> {
  return (action: T | Promise<T>) =>
    action instanceof Promise ? action.then(dispatch) : dispatch(action);
}

const useAsyncReducer = (
  reducer: (state: State, action: Actions) => State,
  initialState: State
) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const asyncDispatch = useMemo(() => wrapAsync(dispatch), [dispatch]);

  return [state, asyncDispatch];
};

export const Context = createContext<{
  state: State;
  dispatch: AsyncDispatch<Actions>;
}>({ state: initialState, dispatch: () => {} });

export const Store: React.FC<{}> = ({ children }) => {
  const [state, dispatch] = useAsyncReducer(reducer, initialState) as [
    State,
    AsyncDispatch<Actions>
  ];

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};
