import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Col, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import { Context } from "./context/store";

import { Button } from "./designSystem/Button";
import { Address, CountryAndState, isValidAddress } from "./model";

const Country: React.FC<{ country: string; onChange: (string) => void }> = ({
  country,
  onChange,
}) => (
  <InputGroup className="mb-3">
    <Form.Select
      aria-label="Country"
      onChange={(e) => onChange(e.target.value)}
      value={country}
    >
      <option value="GB">United Kingdom</option>
      <option disabled>-</option>
      <option value="AF">Afghanistan</option>
      <option value="AX">Åland Islands</option>
      <option value="AL">Albania</option>
      <option value="DZ">Algeria</option>
      <option value="AS">American Samoa</option>
      <option value="AD">Andorra</option>
      <option value="AO">Angola</option>
      <option value="AI">Anguilla</option>
      <option value="AQ">Antarctica</option>
      <option value="AG">Antigua and Barbuda</option>
      <option value="AR">Argentina</option>
      <option value="AM">Armenia</option>
      <option value="AW">Aruba</option>
      <option value="AU">Australia</option>
      <option value="AT">Austria</option>
      <option value="AZ">Azerbaijan</option>
      <option value="BS">Bahamas</option>
      <option value="BH">Bahrain</option>
      <option value="BD">Bangladesh</option>
      <option value="BB">Barbados</option>
      <option value="BY">Belarus</option>
      <option value="BE">Belgium</option>
      <option value="BZ">Belize</option>
      <option value="BJ">Benin</option>
      <option value="BM">Bermuda</option>
      <option value="BT">Bhutan</option>
      <option value="BO">Bolivia, Plurinational State of</option>
      <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
      <option value="BA">Bosnia and Herzegovina</option>
      <option value="BW">Botswana</option>
      <option value="BV">Bouvet Island</option>
      <option value="BR">Brazil</option>
      <option value="IO">British Indian Ocean Territory</option>
      <option value="BN">Brunei Darussalam</option>
      <option value="BG">Bulgaria</option>
      <option value="BF">Burkina Faso</option>
      <option value="BI">Burundi</option>
      <option value="KH">Cambodia</option>
      <option value="CM">Cameroon</option>
      <option value="CA">Canada</option>
      <option value="CV">Cape Verde</option>
      <option value="KY">Cayman Islands</option>
      <option value="CF">Central African Republic</option>
      <option value="TD">Chad</option>
      <option value="CL">Chile</option>
      <option value="CN">China</option>
      <option value="CX">Christmas Island</option>
      <option value="CC">Cocos (Keeling) Islands</option>
      <option value="CO">Colombia</option>
      <option value="KM">Comoros</option>
      <option value="CG">Congo</option>
      <option value="CD">Congo, the Democratic Republic of the</option>
      <option value="CK">Cook Islands</option>
      <option value="CR">Costa Rica</option>
      <option value="CI">Côte d'Ivoire</option>
      <option value="HR">Croatia</option>
      <option value="CU">Cuba</option>
      <option value="CW">Curaçao</option>
      <option value="CY">Cyprus</option>
      <option value="CZ">Czech Republic</option>
      <option value="DK">Denmark</option>
      <option value="DJ">Djibouti</option>
      <option value="DM">Dominica</option>
      <option value="DO">Dominican Republic</option>
      <option value="EC">Ecuador</option>
      <option value="EG">Egypt</option>
      <option value="SV">El Salvador</option>
      <option value="GQ">Equatorial Guinea</option>
      <option value="ER">Eritrea</option>
      <option value="EE">Estonia</option>
      <option value="ET">Ethiopia</option>
      <option value="FK">Falkland Islands (Malvinas)</option>
      <option value="FO">Faroe Islands</option>
      <option value="FJ">Fiji</option>
      <option value="FI">Finland</option>
      <option value="FR">France</option>
      <option value="GF">French Guiana</option>
      <option value="PF">French Polynesia</option>
      <option value="TF">French Southern Territories</option>
      <option value="GA">Gabon</option>
      <option value="GM">Gambia</option>
      <option value="GE">Georgia</option>
      <option value="DE">Germany</option>
      <option value="GH">Ghana</option>
      <option value="GI">Gibraltar</option>
      <option value="GR">Greece</option>
      <option value="GL">Greenland</option>
      <option value="GD">Grenada</option>
      <option value="GP">Guadeloupe</option>
      <option value="GU">Guam</option>
      <option value="GT">Guatemala</option>
      <option value="GG">Guernsey</option>
      <option value="GN">Guinea</option>
      <option value="GW">Guinea-Bissau</option>
      <option value="GY">Guyana</option>
      <option value="HT">Haiti</option>
      <option value="HM">Heard Island and McDonald Islands</option>
      <option value="VA">Holy See (Vatican City State)</option>
      <option value="HN">Honduras</option>
      <option value="HK">Hong Kong</option>
      <option value="HU">Hungary</option>
      <option value="IS">Iceland</option>
      <option value="IN">India</option>
      <option value="ID">Indonesia</option>
      <option value="IR">Iran, Islamic Republic of</option>
      <option value="IQ">Iraq</option>
      <option value="IE">Ireland</option>
      <option value="IM">Isle of Man</option>
      <option value="IL">Occupied Palestine</option>
      <option value="IT">Italy</option>
      <option value="JM">Jamaica</option>
      <option value="JP">Japan</option>
      <option value="JE">Jersey</option>
      <option value="JO">Jordan</option>
      <option value="KZ">Kazakhstan</option>
      <option value="KE">Kenya</option>
      <option value="KI">Kiribati</option>
      <option value="KP">Korea, Democratic People's Republic of</option>
      <option value="KR">Korea, Republic of</option>
      <option value="KW">Kuwait</option>
      <option value="KG">Kyrgyzstan</option>
      <option value="LA">Lao People's Democratic Republic</option>
      <option value="LV">Latvia</option>
      <option value="LB">Lebanon</option>
      <option value="LS">Lesotho</option>
      <option value="LR">Liberia</option>
      <option value="LY">Libya</option>
      <option value="LI">Liechtenstein</option>
      <option value="LT">Lithuania</option>
      <option value="LU">Luxembourg</option>
      <option value="MO">Macao</option>
      <option value="MK">Macedonia, the former Yugoslav Republic of</option>
      <option value="MG">Madagascar</option>
      <option value="MW">Malawi</option>
      <option value="MY">Malaysia</option>
      <option value="MV">Maldives</option>
      <option value="ML">Mali</option>
      <option value="MT">Malta</option>
      <option value="MH">Marshall Islands</option>
      <option value="MQ">Martinique</option>
      <option value="MR">Mauritania</option>
      <option value="MU">Mauritius</option>
      <option value="YT">Mayotte</option>
      <option value="MX">Mexico</option>
      <option value="FM">Micronesia, Federated States of</option>
      <option value="MD">Moldova, Republic of</option>
      <option value="MC">Monaco</option>
      <option value="MN">Mongolia</option>
      <option value="ME">Montenegro</option>
      <option value="MS">Montserrat</option>
      <option value="MA">Morocco</option>
      <option value="MZ">Mozambique</option>
      <option value="MM">Myanmar</option>
      <option value="NA">Namibia</option>
      <option value="NR">Nauru</option>
      <option value="NP">Nepal</option>
      <option value="NL">Netherlands</option>
      <option value="NC">New Caledonia</option>
      <option value="NZ">New Zealand</option>
      <option value="NI">Nicaragua</option>
      <option value="NE">Niger</option>
      <option value="NG">Nigeria</option>
      <option value="NU">Niue</option>
      <option value="NF">Norfolk Island</option>
      <option value="MP">Northern Mariana Islands</option>
      <option value="NO">Norway</option>
      <option value="OM">Oman</option>
      <option value="PK">Pakistan</option>
      <option value="PW">Palau</option>
      <option value="PS">Palestinian Territory, Occupied</option>
      <option value="PA">Panama</option>
      <option value="PG">Papua New Guinea</option>
      <option value="PY">Paraguay</option>
      <option value="PE">Peru</option>
      <option value="PH">Philippines</option>
      <option value="PN">Pitcairn</option>
      <option value="PL">Poland</option>
      <option value="PT">Portugal</option>
      <option value="PR">Puerto Rico</option>
      <option value="QA">Qatar</option>
      <option value="RE">Réunion</option>
      <option value="RO">Romania</option>
      <option value="RU">Russian Federation</option>
      <option value="RW">Rwanda</option>
      <option value="BL">Saint Barthélemy</option>
      <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
      <option value="KN">Saint Kitts and Nevis</option>
      <option value="LC">Saint Lucia</option>
      <option value="MF">Saint Martin (French part)</option>
      <option value="PM">Saint Pierre and Miquelon</option>
      <option value="VC">Saint Vincent and the Grenadines</option>
      <option value="WS">Samoa</option>
      <option value="SM">San Marino</option>
      <option value="ST">Sao Tome and Principe</option>
      <option value="SA">Saudi Arabia</option>
      <option value="SN">Senegal</option>
      <option value="RS">Serbia</option>
      <option value="SC">Seychelles</option>
      <option value="SL">Sierra Leone</option>
      <option value="SG">Singapore</option>
      <option value="SX">Sint Maarten (Dutch part)</option>
      <option value="SK">Slovakia</option>
      <option value="SI">Slovenia</option>
      <option value="SB">Solomon Islands</option>
      <option value="SO">Somalia</option>
      <option value="ZA">South Africa</option>
      <option value="GS">South Georgia and the South Sandwich Islands</option>
      <option value="SS">South Sudan</option>
      <option value="ES">Spain</option>
      <option value="LK">Sri Lanka</option>
      <option value="SD">Sudan</option>
      <option value="SR">Suriname</option>
      <option value="SJ">Svalbard and Jan Mayen</option>
      <option value="SZ">Swaziland</option>
      <option value="SE">Sweden</option>
      <option value="CH">Switzerland</option>
      <option value="SY">Syrian Arab Republic</option>
      <option value="TW">Taiwan, Province of China</option>
      <option value="TJ">Tajikistan</option>
      <option value="TZ">Tanzania, United Republic of</option>
      <option value="TH">Thailand</option>
      <option value="TL">Timor-Leste</option>
      <option value="TG">Togo</option>
      <option value="TK">Tokelau</option>
      <option value="TO">Tonga</option>
      <option value="TT">Trinidad and Tobago</option>
      <option value="TN">Tunisia</option>
      <option value="TR">Turkey</option>
      <option value="TM">Turkmenistan</option>
      <option value="TC">Turks and Caicos Islands</option>
      <option value="TV">Tuvalu</option>
      <option value="UG">Uganda</option>
      <option value="UA">Ukraine</option>
      <option value="AE">United Arab Emirates</option>
      <option value="US">United States</option>
      <option value="UM">United States Minor Outlying Islands</option>
      <option value="UY">Uruguay</option>
      <option value="UZ">Uzbekistan</option>
      <option value="VU">Vanuatu</option>
      <option value="VE">Venezuela, Bolivarian Republic of</option>
      <option value="VN">Viet Nam</option>
      <option value="VG">Virgin Islands, British</option>
      <option value="VI">Virgin Islands, U.S.</option>
      <option value="WF">Wallis and Futuna</option>
      <option value="EH">Western Sahara</option>
      <option value="YE">Yemen</option>
      <option value="ZM">Zambia</option>
      <option value="ZW">Zimbabwe</option>
    </Form.Select>
  </InputGroup>
);

const usStates = new Map(
  [
    ["Alabama", "AL"],
    ["Alaska", "AK"],
    ["Arkansas", "AR"],
    ["Arizona", "AZ"],
    ["California", "CA"],
    ["Colorado", "CO"],
    ["Connecticut", "CT"],
    ["D.C.", "DC"],
    ["Delaware", "DE"],
    ["Florida", "FL"],
    ["Georgia", "GA"],
    ["Hawaii", "HI"],
    ["Iowa", "IA"],
    ["Idaho", "ID"],
    ["Illinois", "IL"],
    ["Indiana", "IN"],
    ["Kansas", "KS"],
    ["Kentucky", "KY"],
    ["Louisiana", "LA"],
    ["Massachusetts", "MA"],
    ["Maryland", "MD"],
    ["Maine", "ME"],
    ["Michigan", "MI"],
    ["Minnesota", "MN"],
    ["Missouri", "MO"],
    ["Mississippi", "MS"],
    ["Montana", "MT"],
    ["North Carolina", "NC"],
    ["North Dakota", "ND"],
    ["Nebraska", "NE"],
    ["New Hampshire", "NH"],
    ["New Jersey", "NJ"],
    ["New Mexico", "NM"],
    ["Nevada", "NV"],
    ["New York", "NY"],
    ["Oklahoma", "OK"],
    ["Ohio", "OH"],
    ["Oregon", "OR"],
    ["Pennsylvania", "PA"],
    ["Rhode Island", "RI"],
    ["South Carolina", "SC"],
    ["South Dakota", "SD"],
    ["Tennessee", "TN"],
    ["Texas", "TX"],
    ["Utah", "UT"],
    ["Virginia", "VA"],
    ["Vermont", "VT"],
    ["Washington", "WA"],
    ["Wisconsin", "WI"],
    ["West Virginia", "WV"],
    ["Wyoming", "WY"],
  ].map(([name, code]) => [code, name])
);

const USState: React.FC<{ state: string; onChange: (string) => void }> = ({
  state,
  onChange,
}) => {
  return (
    <InputGroup className="mb-3">
      <Form.Select
        aria-label="US State"
        onChange={(e) => onChange(e.target.value)}
        value={state}
        required
      >
        {Array.from(usStates).map(([code, name]) => (
          <option key={code} value={code}>
            {name}
          </option>
        ))}
      </Form.Select>
    </InputGroup>
  );
};

export const AddressStep: React.FC<{
  onChange: (
    country: CountryAndState["country"],
    state: CountryAndState["state"],
    address: Address
  ) => void;
  isAddressStepDone: boolean;
  next: () => void;
}> = ({ onChange, isAddressStepDone, next }) => {
  const {
    state: { donation },
  } = useContext(Context);

  const firstNameRef = useRef<HTMLInputElement>(undefined);
  const lastNameRef = useRef<HTMLInputElement>(undefined);
  const emailRef = useRef<HTMLInputElement>(undefined);
  const telephoneRef = useRef<HTMLInputElement>(undefined);
  const addressRef = useRef<HTMLInputElement>(undefined);
  const address2Ref = useRef<HTMLInputElement>(undefined);
  const cityRef = useRef<HTMLInputElement>(undefined);
  const postCodeRef = useRef<HTMLInputElement>(undefined);

  const [country, setCountry] = useState<string | undefined>(undefined);
  const [state, setState] = useState<string | undefined>(undefined);

  useLayoutEffect(() => {
    if (firstNameRef.current && donation.donor?.address?.firstName)
      firstNameRef.current.value = donation.donor.address.firstName;

    if (lastNameRef.current && donation.donor?.address?.lastName)
      lastNameRef.current.value = donation.donor.address.lastName;

    if (emailRef.current && donation.donor?.address?.email)
      emailRef.current.value = donation.donor.address.email;

    if (telephoneRef.current && donation.donor?.address?.telephone)
      telephoneRef.current.value = donation.donor.address.telephone;

    if (addressRef.current && donation.donor?.address?.address)
      addressRef.current.value = donation.donor.address.address;

    if (address2Ref.current && donation.donor?.address?.address2)
      address2Ref.current.value = donation.donor.address.address2;

    if (cityRef.current && donation.donor?.address?.city)
      cityRef.current.value = donation.donor.address.city;

    if (postCodeRef.current && donation.donor?.address?.postCode)
      postCodeRef.current.value = donation.donor.address.postCode;

    setCountry(donation.country ?? "GB");

    if (donation.state) setState(donation.state);
  }, []);

  const [result, setResult] = useState<Address>({});
  const [countryResult, setCountryResult] = useState(undefined);
  const [stateResult, setStateResult] = useState(undefined);

  const update = () => {
    setResult({
      firstName: firstNameRef.current?.value || undefined,
      lastName: lastNameRef.current?.value || undefined,
      email: emailRef.current?.value || undefined,
      telephone: telephoneRef.current?.value || undefined,
      address: addressRef.current?.value || undefined,
      address2: address2Ref.current?.value || undefined,
      city: cityRef.current?.value || undefined,
      postCode: postCodeRef.current?.value || undefined,
    });

    setCountryResult(country || undefined);
    setStateResult(state || undefined);
  };

  useEffect(() => {
    update();
  }, [country, state]);

  useEffect(() => {
    onChange(countryResult, stateResult, result);
  }, [countryResult, stateResult, result]);

  return (
    <>
      <div style={{ height: 20, marginBlockEnd: "1em" }}></div>
      <Row>
        <Col>
          <p className="lead">My details:</p>
        </Col>
      </Row>
      <form>
        <Row>
          <Col md="6">
            <Form.Label htmlFor="donor-first-name">First Name</Form.Label>
            <InputGroup className="mb-3">
              <FormControl
                id="donor-first-name"
                ref={firstNameRef}
                onChange={update}
                required
              />
            </InputGroup>
          </Col>
          <Col md="6">
            <Form.Label htmlFor="donor-last-name">Last Name</Form.Label>
            <InputGroup className="mb-3">
              <FormControl
                id="donor-last-name"
                ref={lastNameRef}
                onChange={update}
                required
              />
            </InputGroup>
          </Col>
          <Col md="6">
            <Form.Label htmlFor="donor-email">Email</Form.Label>
            <InputGroup className="mb-3">
              <FormControl
                id="donor-email"
                ref={emailRef}
                onChange={update}
                required
              />
            </InputGroup>
          </Col>
          <Col md="6">
            <Form.Label htmlFor="donor-telephone">Telephone</Form.Label>
            <InputGroup className="mb-3">
              <FormControl
                id="donor-telephone"
                ref={telephoneRef}
                onChange={update}
                required
              />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label htmlFor="donor-address">Address</Form.Label>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <InputGroup className="mb-3">
              <FormControl
                id="donor-address"
                ref={addressRef}
                onChange={update}
                placeholder="Address"
                required
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <FormControl
                id="donor-address2"
                onChange={update}
                ref={address2Ref}
              />
            </InputGroup>
          </Col>
          <Col md="6">
            <InputGroup className="mb-3">
              <FormControl
                id="donor-city"
                ref={cityRef}
                onChange={update}
                placeholder="Town/City"
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <FormControl
                id="donor-postcode"
                ref={postCodeRef}
                onChange={update}
                placeholder="Post Code/ZIP"
              />
            </InputGroup>
          </Col>
          <Col md="6">
            <Country
              country={country}
              onChange={(newCountry) => setCountry(newCountry)}
            />
          </Col>
          {country === "US" && (
            <Col md="6">
              <USState
                state={state}
                onChange={(newState) => setState(newState)}
              />
            </Col>
          )}
        </Row>
        {!isAddressStepDone && (
          <>
            <div style={{ height: 20 }}></div>
            <Row>
              <Col>
                <Button
                  type="submit"
                  disabled={!isValidAddress(countryResult, stateResult, result)}
                  onClick={next}
                >
                  Next
                </Button>
              </Col>
            </Row>
          </>
        )}
      </form>
    </>
  );
};
