import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { groupBy } from "ramda";
import { Card, Form, FormControl, InputGroup } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { throttle } from "throttle-debounce";
import { v4 as uuidv4 } from "uuid";

import { AddressStep } from "./AddressStep";
import { ApiDonationType, ApiPriceHandle } from "./apiAdapter";
import {
  getSetDonationAction,
  getSetSelectedDonationTypeId,
} from "./context/reducer";
import { Context } from "./context/store";
import { BlockButton, Button, FlashingButton } from "./designSystem/Button";
import { GiftaidIt } from "./GiftaidIt";
import {
  Address,
  CountryAndState,
  DonationAmount,
  Intent,
  intents,
  isValidAddress,
  isValidAmount,
  isValidIntent,
  isValidIsGiftaid,
  isValidIsMonthly,
  isValidPaymentMethod,
  PaymentMethod,
} from "./model";
import { faCheck, faCreditCard } from "@fortawesome/free-solid-svg-icons";

import { faPaypal } from "@fortawesome/free-brands-svg-icons";
import {
  mainTextColor,
  mainTextFontFamily,
  mainTextFontWeight,
} from "./designSystem/styles";
import styled from "styled-components";

const PriceHandlesSeparator = styled.div`
  height: 74px;
  width: 100%;
  background: linear-gradient(
    to bottom,
    transparent 0%,
    transparent calc(50% - 0.81px),
    rgb(243, 240, 235) calc(50% - 0.8px),
    rgb(243, 240, 235) calc(50% + 0.8px),
    transparent calc(50% + 0.81px),
    transparent 100%
  );

  @media (min-width: 992px) {
    height: 100%;
    width: 10px;
    display: flex;
    flexdirection: column;
    justifycontent: center;
    alignitems: center;
    background: linear-gradient(
      to right,
      transparent 0%,
      transparent calc(50% - 0.81px),
      rgb(243, 240, 235) calc(50% - 0.8px),
      rgb(243, 240, 235) calc(50% + 0.8px),
      transparent calc(50% + 0.81px),
      transparent 100%
    );
  }
`;

const PriceHandles = ({
  selectedPriceHandleId,
  priceHandles,
  onClick,
}: {
  selectedPriceHandleId: ApiPriceHandle["Id"];
  priceHandles: ApiPriceHandle[];
  onClick: (priceHandleId: ApiPriceHandle["Id"]) => void;
}) => (
  <Row>
    {(
      Object.entries(groupBy(({ Group }) => Group, priceHandles)) as [
        string,
        ApiPriceHandle[]
      ][]
    ).map(([group, groupPriceHandles]) => (
      <Row key={group}>
        {groupPriceHandles
          .sort((a, b) => {
            // if (a.Group !== b.Group) return a.Group.localeCompare(b.Group);

            if (!a.IsMonthly && b.IsMonthly) return -1;
            if (a.IsMonthly && !b.IsMonthly) return 1;

            return a.Amount - b.Amount;
          })
          .map((priceHandle) => (
            <Col
              key={priceHandle.Id}
              xl="3"
              style={{
                padding: 10,
                // flexGrow: 1,
              }}
            >
              <div
                style={{
                  backgroundColor:
                    selectedPriceHandleId === priceHandle.Id
                      ? "#ce0000"
                      : "rgb(0, 124, 0)",
                  color: "white",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  alignItems: "center",
                  padding: 10,
                  cursor: "pointer",
                }}
                onClick={() => onClick(priceHandle.Id)}
              >
                {priceHandle.Group && (
                  <p
                    className="lead"
                    style={{
                      margin: 0,
                      textAlign: "center",
                    }}
                  >
                    {priceHandle.Group}
                  </p>
                )}
                <h3
                  style={{
                    fontFamily: mainTextFontFamily,
                    fontSize: 36,
                    fontWeight: mainTextFontWeight,
                    color: mainTextColor,
                    margin: 0,
                  }}
                >
                  £{priceHandle.Amount}
                  {priceHandle.IsMonthly && (
                    <span style={{ fontSize: 24 }}> per month</span>
                  )}
                </h3>
                <p
                  className="lead"
                  style={{
                    margin: 0,
                    textAlign: "center",
                  }}
                >
                  {priceHandle.Description}
                </p>
              </div>
            </Col>
          ))}
      </Row>
    ))}
  </Row>
);

export const DonationAmountPage: React.FC<{
  donationType: ApiDonationType;
}> = ({ donationType }) => {
  const {
    dispatch,
    state: { apiDonationTypes, donation },
  } = useContext(Context);

  const availableIntents: Intent[] = useMemo(() => {
    if (donationType.Name.toUpperCase() === "ZAKAT")
      return ["Zakat"] as Intent[];
    else return intents as unknown as Intent[];
  }, [apiDonationTypes, donationType]);

  const [showOtherWaysToDonate, setShowOtherWaysToDonate] = useState(false);

  const isLimitedToSingle = useMemo(
    () => !donation.isMonthly && donation.donationAmounts.length > 0,
    [donation]
  );

  const isLimitedToMonthly = useMemo(
    () => donation.isMonthly && donation.donationAmounts.length > 0,
    [donation]
  );

  const emailOptInRef = useRef<HTMLInputElement | undefined>(undefined);

  useLayoutEffect(() => {
    if (emailOptInRef.current && donation.emailOptIn)
      emailOptInRef.current.checked = !!donation.emailOptIn;
  }, []);

  const [donationAmount, setDonationAmount] = useState<DonationAmount>({
    id: uuidv4(),
    donationTypeId: donationType.Id,
  });

  const [selectedPriceHandleId, setSelectedPriceHandleId] = useState<
    number | undefined
  >(undefined);

  // const [isPriceHandlesStepDone, setIsPriceHandlesStepDone] = useState(false);
  const [isIsMonthlyStepDone, setIsIsMonthlyStepDone] = useState(
    isValidIsMonthly(donation.isMonthly)
  );
  const [isIsGiftaidStepDone, setIsIsGiftaidStepDone] = useState(
    isValidIsGiftaid(donation.isGiftaid)
  );
  const [isAddressStepDone, setIsAddressStepDone] = useState(
    isValidAddress(donation.country, donation.state, donation.donor?.address)
  );
  const [isPaymentMethodStepDone, setIsPaymentMethodStepDone] = useState(
    isValidPaymentMethod(donation.paymentMethod)
  );

  const [isAmountStepDone, setIsAmountStepDone] = useState(false);
  const [isIntentStepDone, setIsIntentStepDone] = useState(false);

  const onPriceHandleChange = async () => {
    const priceHandle = donationType.PriceHandles.find(
      ({ Id }) => Id === selectedPriceHandleId
    )!;

    await onIsMonthlyChange(priceHandle.IsMonthly, true);

    onAmountChange(priceHandle.Amount, true);
    if (amountRef.current)
      amountRef.current.value = priceHandle.Amount.toString();

    setDonationAmount((prev) => ({
      ...prev,
      donationTypeGroup: priceHandle.Group,
    }));
  };

  useEffect(() => {
    if (!selectedPriceHandleId) return;

    onPriceHandleChange();
  }, [selectedPriceHandleId]);

  const onIsMonthlyChange = async (
    newIsMonthly: boolean,
    isPriceHandleChange: boolean
  ) => {
    await dispatch(
      getSetDonationAction({ ...donation, isMonthly: newIsMonthly })
    );

    if (!isPriceHandleChange) setSelectedPriceHandleId(undefined);
  };

  const onAmountChange = useCallback(
    throttle(1000, (newAmount: number, isPriceHandleChange: boolean) => {
      setDonationAmount((prev) => ({ ...prev, amount: newAmount }));

      if (!isPriceHandleChange) setSelectedPriceHandleId(undefined);
    }),
    []
  );

  const onIsGiftaidChange = async (newIsGiftaid: boolean) => {
    await dispatch(
      getSetDonationAction({ ...donation, isGiftaid: newIsGiftaid })
    );
  };

  const onIntentChange = (newIntent: Intent) => {
    setDonationAmount((prev) => ({ ...prev, intent: newIntent }));

    if (isValidIntent(newIntent)) setIsIntentStepDone(true);
  };

  useEffect(() => {
    if (!isIntentStepDone && availableIntents.length === 1) {
      onIntentChange(availableIntents[0]);
    }
  }, [availableIntents]);

  const amountRef = useRef<HTMLInputElement | undefined>(undefined);

  const onAddressChange = async (
    newCountry: CountryAndState["country"],
    newState: CountryAndState["state"],
    newAddress: Address
  ) => {
    await dispatch(
      getSetDonationAction({
        ...donation,
        country: newCountry,
        state: newState,
        donor: { ...donation.donor, address: newAddress },
      })
    );
  };

  const onPaymentMethodChange = async (newPaymentMethod: PaymentMethod) => {
    await dispatch(
      getSetDonationAction({
        ...donation,
        paymentMethod: newPaymentMethod,
      })
    );

    if (isValidPaymentMethod(newPaymentMethod))
      setIsPaymentMethodStepDone(true);
  };

  const onEmailOptInChange = async (newEmailOptIn: boolean) => {
    await dispatch(
      getSetDonationAction({
        ...donation,
        emailOptIn: newEmailOptIn,
      })
    );
  };

  const completeAmountStep = () => {
    if (
      isValidIsMonthly(donation.isMonthly) &&
      isValidAmount(donationAmount.amount)
    )
      setIsAmountStepDone(true);
  };

  const completePriceHandlesStep = async () => {
    await onPriceHandleChange();

    completeAmountStep();
  };

  const completeIsGiftaidStep = async () => {
    if (!isValidIsGiftaid(donation.isGiftaid)) {
      await dispatch(getSetDonationAction({ ...donation, isGiftaid: false }));
    }

    setIsIsGiftaidStepDone(true);
  };

  const completeAddressStep = () => {
    if (
      isValidAddress(donation.country, donation.state, donation.donor?.address)
    )
      setIsAddressStepDone(true);
  };

  const addDonationAmount = async () => {
    await dispatch(getSetSelectedDonationTypeId(undefined));

    await dispatch(
      getSetDonationAction({
        ...donation,
        donationAmounts: [...donation.donationAmounts, { ...donationAmount }],
      })
    );
  };

  const hasPriceHandles = donationType.PriceHandles.length > 0;
  const hasDonationAmounts = donation.donationAmounts.length > 0;

  const showSteps = (() => {
    const result = {
      // priceHandles: hasPriceHandles,
      // isMonthly: isLimitedToMonthly ? true : isLimitedToSingle ? false : false,
      amount: true,
      intent: false,
      giftaid: false,
      address: false,
      paymentMethod: false,
      complete: false,
    };

    if (!isAmountStepDone) {
      // if (!isIsMonthlyStepDone) {
      //   result.isMonthly = true;
      // }

      // result.amount = true;

      return result;
    }

    // if (hasPriceHandles && !isPriceHandlesStepDone)
    //   return result;

    // if (!isValidIsMonthly(donation.isMonthly)) return result;
    // result.isMonthly = true;

    // if (!isIsMonthlyStepDone) return result;

    // if (!hasPriceHandles) result.amount = true;

    // if (!isValidAmount(donationAmount.amount)) return result;
    // if (!isAmountStepDone) return result;

    if (!hasDonationAmounts) result.giftaid = true;

    if (!isValidIsGiftaid(donation.isGiftaid)) return result;
    if (!isIsGiftaidStepDone) return result;

    if (availableIntents.length > 1) result.intent = true;

    // if (!isValidIntent(donationAmount.intent)) return result;
    if (!isIntentStepDone) return result;

    if (!hasDonationAmounts) result.address = true;

    // if (
    //   !isValidAddress(donation.country, donation.state, donation.donor?.address)
    // )
    //   return result;
    if (!isAddressStepDone) return result;

    if (!hasDonationAmounts) result.paymentMethod = true;

    // if (!isValidPaymentMethod(donation.paymentMethod)) return result;
    if (!isPaymentMethodStepDone) return result;

    result.complete = true;

    return result;
  })();

  if (!donationType.IsActive)
    return <div>This campaign is no longer running.</div>;

  return (
    <>
      <Container fluid style={{ paddingTop: 20, paddingBottom: 20 }}>
        {/* <Row>
          <Col>
            <Card>
              <Card.Body>
                <Card.Text>
                  Thank you for donating to IHRC Trust. You can use this page to
                  make a donation by Credit/Debit Card or PayPal. For other ways
                  to donate, including bank transfer and standing order, please{" "}
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowOtherWaysToDonate(true);
                    }}
                  >
                    click here
                  </a>
                  .
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row> */}
        {/* <div style={{ height: 20, marginBlockEnd: "1em" }}></div> */}
        <Row>
          <Col>
            <p className="lead">
              {donationType.DonationPrompt}. You can use this page to make a
              donation by Credit/Debit Card or PayPal. For other ways to donate,
              including bank transfer and standing order, please contact us.
            </p>
          </Col>
        </Row>

        {showSteps.amount && (
          <>
            <Row>
              <Col>
                {donation.donationAmounts.length > 0 && (
                  <>
                    {donation.isMonthly ? (
                      <p>
                        If you would like to add another one-off donation,
                        please pay for this monthly donation first, then come
                        back and make a separate one-off donation.
                      </p>
                    ) : (
                      <p>
                        If you would like to add another monthly donation,
                        please pay for this one-off donation first, then come
                        back and make a separate monthly donation.
                      </p>
                    )}
                  </>
                )}
                <p className="lead">I would like to donate:</p>
              </Col>
            </Row>

            {(() => {
              const frequency =
                !isLimitedToMonthly && !isLimitedToSingle ? (
                  <>
                    <Row>
                      {!isLimitedToMonthly && (
                        <Col md="6">
                          <BlockButton
                            active={donation.isMonthly === false}
                            onClick={() => onIsMonthlyChange(false, false)}
                          >
                            Just once
                          </BlockButton>
                        </Col>
                      )}
                      {!isLimitedToSingle && (
                        <Col md="6">
                          <BlockButton
                            active={donation.isMonthly === true}
                            onClick={() => onIsMonthlyChange(true, false)}
                          >
                            Monthly
                          </BlockButton>
                        </Col>
                      )}
                    </Row>
                    <div style={{ height: 20, marginBlockEnd: "1em" }}></div>{" "}
                  </>
                ) : (
                  <></>
                );

              const amount = (
                <Row>
                  <Col>
                    <InputGroup className="input-group-lg">
                      <InputGroup.Text id="amount-prefix">£</InputGroup.Text>
                      <FormControl
                        ref={amountRef}
                        type="number"
                        min="0"
                        // step="0.01"
                        placeholder="0.00"
                        aria-label="Amount"
                        aria-describedby="amount-prefix"
                        onChange={(e) => onAmountChange(+e.target.value)}
                        onKeyUp={(e) => onAmountChange(+e.currentTarget.value)}
                        // value={(donationAmount.amount ?? 0).toFixed(2)}
                      />
                      {donation.isMonthly && (
                        <InputGroup.Text>per month</InputGroup.Text>
                      )}
                    </InputGroup>
                  </Col>
                </Row>
              );

              // IsLimitedToPriceHandles ?
              // just show price handles
              // else
              // show any price handles + manual

              const availablePriceHandles = donationType.PriceHandles.filter(
                (priceHandle) =>
                  isLimitedToMonthly
                    ? priceHandle.IsMonthly
                    : isLimitedToSingle
                    ? !priceHandle.IsMonthly
                    : true
              );

              const hasAvailablePriceHandles = availablePriceHandles.length > 0;

              if (donationType.IsLimitedToPriceHandles) {
                return !hasAvailablePriceHandles ? (
                  <Row>
                    <Col>
                      <p className="lead">
                        No{" "}
                        {isLimitedToMonthly
                          ? "monthly "
                          : isLimitedToSingle
                          ? "one-off "
                          : ""}
                        donations are available for this campaign
                      </p>
                    </Col>
                  </Row>
                ) : (
                  <PriceHandles
                    priceHandles={availablePriceHandles}
                    selectedPriceHandleId={selectedPriceHandleId}
                    onClick={(priceHandleId) =>
                      setSelectedPriceHandleId(priceHandleId)
                    }
                  />
                );
              }

              if (hasAvailablePriceHandles) {
                return (
                  <Row>
                    <Col>
                      <PriceHandles
                        priceHandles={availablePriceHandles}
                        selectedPriceHandleId={selectedPriceHandleId}
                        onClick={(priceHandleId) =>
                          setSelectedPriceHandleId(priceHandleId)
                        }
                      />
                    </Col>
                    <Col lg="auto" xs={12}>
                      <PriceHandlesSeparator />
                    </Col>
                    <Col lg={4}>
                      {frequency}
                      {amount}
                    </Col>
                  </Row>
                );
              }

              return (
                <Row>
                  <Col>
                    {frequency}
                    {amount}
                  </Col>
                </Row>
              );
            })()}

            {!isAmountStepDone && (
              <>
                <div style={{ height: 20, marginBlockEnd: "1em" }}></div>
                <Row>
                  <Col>
                    <Button
                      disabled={
                        !isValidIsMonthly(donation.isMonthly) ||
                        !isValidAmount(donationAmount.amount)
                      }
                      onClick={completeAmountStep}
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}

        {/* {showSteps.priceHandles && (
          <>
            <div style={{ height: 20, marginBlockEnd: "1em" }}></div>
            {isLimitedToMonthly &&
            donationType.PriceHandles.filter((priceHandle) =>
              isLimitedToMonthly ? priceHandle.IsMonthly : true
            ).length === 0 ? (
              <p className="lead">
                No monthly donations are available for this campaign
              </p>
            ) : isLimitedToSingle &&
              donationType.PriceHandles.filter((priceHandle) =>
                isLimitedToMonthly ? !priceHandle.IsMonthly : true
              ).length === 0 ? (
              <p className="lead">
                No one-off donations are available for this campaign
              </p>
            ) : (
              <>
                <Row>
                  <Col>
                    <p className="lead">I would like to donate:</p>
                  </Col>
                </Row>
                <Row>
                  {donationType.PriceHandles.filter((priceHandle) =>
                    isLimitedToMonthly
                      ? priceHandle.IsMonthly
                      : isLimitedToSingle
                      ? !priceHandle.IsMonthly
                      : true
                  ).map((priceHandle) => (
                    <Col
                      key={priceHandle.Id}
                      md="3"
                      style={{
                        padding: 10,
                        flexGrow: 1,
                      }}
                    >
                      <div
                        style={{
                          backgroundColor:
                            selectedPriceHandleId === priceHandle.Id
                              ? "#ce0000"
                              : "rgb(0, 124, 0)",
                          color: "white",
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                          // justifyContent: "center",
                          alignItems: "center",
                          padding: 10,
                          cursor: "pointer",
                        }}
                        onClick={() => setSelectedPriceHandleId(priceHandle.Id)}
                      >
                        <h3
                          style={{
                            fontFamily: mainTextFontFamily,
                            fontSize: 36,
                            fontWeight: mainTextFontWeight,
                            color: mainTextColor,
                            // textShadow: mainTextShadow,
                            // marginBottom: 20,
                          }}
                        >
                          £{priceHandle.Amount}
                          {priceHandle.IsMonthly && (
                            <span style={{ fontSize: 24 }}> per month</span>
                          )}
                        </h3>
                        <p
                          className="lead"
                          style={{ marginBottom: 0, textAlign: "center" }}
                        >
                          {priceHandle.Description}
                        </p>
                      </div>
                    </Col>
                  ))}
                </Row>
                {!isPriceHandlesStepDone && (
                  <>
                    <div style={{ height: 20, marginBlockEnd: "1em" }}></div>
                    <Row>
                      <Col>
                        <Button
                          disabled={!selectedPriceHandleId}
                          onClick={completePriceHandlesStep}
                        >
                          Next
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}
          </>
        )} */}

        {/* {showSteps.isMonthly && (
          <>
            <div style={{ height: 20, marginBlockEnd: "1em" }}></div>
            <Row>
              <Col>
                <p className="lead">I would like to make a donation:</p>
              </Col>
            </Row>
            <Row>
              {!isLimitedToMonthly && (
                <Col md="6">
                  <BlockButton
                    active={donation.isMonthly === false}
                    onClick={() => onIsMonthlyChange(false)}
                  >
                    Just once
                  </BlockButton>
                </Col>
              )}
              {!isLimitedToSingle && (
                <Col md="6">
                  <BlockButton
                    active={donation.isMonthly === true}
                    onClick={() => onIsMonthlyChange(true)}
                  >
                    Monthly
                  </BlockButton>
                </Col>
              )}
            </Row>
          </>
        )} */}

        {/* {showSteps.amount && (
          <>
            <div style={{ height: 20, marginBlockEnd: "1em" }}></div>
            <Row>
              <Col>
                <p className="lead">I would like to donate:</p>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <InputGroup className="input-group-lg">
                  <InputGroup.Text id="amount-prefix">£</InputGroup.Text>
                  <FormControl
                    type="number"
                    min="0"
                    // step="0.01"
                    placeholder="0.00"
                    aria-label="Amount"
                    aria-describedby="amount-prefix"
                    onChange={(e) => onAmountChange(+e.target.value)}
                    onKeyUp={(e) => onAmountChange(+e.currentTarget.value)}
                    // value={(donation.amount ?? 0).toFixed(2)}
                  />
                  {donation.isMonthly && (
                    <InputGroup.Text>per month</InputGroup.Text>
                  )}
                </InputGroup>
              </Col>
            </Row>
            {!isAmountStepDone && (
              <>
                <div style={{ height: 20, marginBlockEnd: "1em" }}></div>
                <Row>
                  <Col>
                    <Button
                      disabled={!isValidAmount(donationAmount.amount)}
                      onClick={completeAmountStep}
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </>
        )} */}

        {showSteps.giftaid && (
          <>
            <div style={{ height: 20, marginBlockEnd: "1em" }}></div>
            <Row>
              <Col>
                <GiftaidIt
                  amount={donationAmount.amount}
                  isGiftaid={donation.isGiftaid}
                  onChange={(newIsGiftaid) => onIsGiftaidChange(newIsGiftaid)}
                />
              </Col>
            </Row>
            {!isIsGiftaidStepDone && (
              <>
                <div style={{ height: 20, marginBlockEnd: "1em" }}></div>
                <Row>
                  <Col>
                    <Button
                      // disabled={!isValidIsGiftaid(donation.isGiftaid)}
                      // always enabled
                      onClick={completeIsGiftaidStep}
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}

        {showSteps.intent && (
          <>
            <div style={{ height: 20, marginBlockEnd: "1em" }}></div>
            <Row>
              <Col>
                <p className="lead">I would like to mark my donation as:</p>
              </Col>
            </Row>
            <Row>
              {availableIntents.map((intent) => (
                <Col key={intent} md="auto">
                  <BlockButton
                    active={donationAmount.intent === intent}
                    onClick={() => onIntentChange(intent)}
                  >
                    {intent}
                  </BlockButton>
                </Col>
              ))}
            </Row>
          </>
        )}

        {showSteps.address && (
          <AddressStep
            onChange={onAddressChange}
            isAddressStepDone={isAddressStepDone}
            next={completeAddressStep}
          />
        )}

        {showSteps.paymentMethod && (
          <>
            <div style={{ height: 20, marginBlockEnd: "1em" }}></div>
            <Row>
              <Col>
                {donation.isMonthly ? (
                  <p className="lead">
                    At this time we can only take payment for monthly donations
                    using Paypal.
                  </p>
                ) : (
                  <p className="lead">I would like to pay using:</p>
                )}
              </Col>
            </Row>
            <Row>
              {!donation.isMonthly && (
                <Col md="auto">
                  <BlockButton
                    active={donation.paymentMethod === "CreditCard"}
                    onClick={() => onPaymentMethodChange("CreditCard")}
                    className="d-flex align-items-center"
                  >
                    <FontAwesomeIcon
                      icon={faCreditCard}
                      style={{
                        fontSize: "2em",
                        marginRight: "0.5em",
                      }}
                    />
                    <div>Credit/Debit Card</div>
                  </BlockButton>
                </Col>
              )}
              <Col md="auto">
                <BlockButton
                  active={donation.paymentMethod === "Paypal"}
                  onClick={() => onPaymentMethodChange("Paypal")}
                  className="d-flex align-items-center"
                >
                  <FontAwesomeIcon
                    icon={faPaypal}
                    style={{
                      fontSize: "2em",
                      marginRight: "0.5em",
                    }}
                  />
                  <div>Paypal</div>
                </BlockButton>
              </Col>
            </Row>
          </>
        )}

        {showSteps.complete && (
          <>
            <div style={{ height: 20, marginBlockEnd: "1em" }}></div>
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <p>
                      The General Data Protection Regulation (GDPR) came into
                      effect on 25th May 2018 and is designed to protect your
                      personal data. In order to process and manage your
                      donation to IHRC Trust, your details will be held
                      securely. If at any time you wish to view, amend or delete
                      your data held with IHRC Trust, kindly contact us with
                      your request.
                    </p>
                    <Form.Check
                      type="switch"
                      label="I would like to receive email updates about current appeals &amp; campaigns"
                      onChange={(e) => onEmailOptInChange(e.target.checked)}
                      ref={emailOptInRef}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <div style={{ height: 20, marginBlockEnd: "1em" }}></div>
            <Row>
              <Col>
                <FlashingButton>
                  <Button onClick={addDonationAmount}>
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{
                        marginRight: "0.5em",
                      }}
                    />
                    Complete my donation
                  </Button>
                </FlashingButton>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
};
