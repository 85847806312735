import { getApiDonationTypes } from "../apiAdapter";
import { Donation } from "../model";
import { State } from "./store";

interface Action {
  type: string;
  payload: any;
}

export interface SetApiDonationTypes extends Action {
  type: "SET_API_DONATION_TYPES";
  payload: State["apiDonationTypes"];
}

export interface SetSelectedDonationTypeId extends Action {
  type: "SET_SELECTED_DONATION_TYPE_ID";
  payload: number;
}

export interface SetDonation extends Action {
  type: "SET_DONATION";
  payload: State["donation"];
}

export type Actions =
  | SetApiDonationTypes
  | SetSelectedDonationTypeId
  | SetDonation;

export const reducer = (state: State, action: Actions) => {
  if (action.type === "SET_API_DONATION_TYPES") {
    return { ...state, apiDonationTypes: action.payload };
  }

  if (action.type === "SET_SELECTED_DONATION_TYPE_ID") {
    return { ...state, selectedDonationTypeId: action.payload };
  }

  if (action.type === "SET_DONATION") {
    return { ...state, donation: action.payload };
  }

  return state;
};

export const getApiDonationTypesAction =
  async (): Promise<SetApiDonationTypes> => {
    const apiDonationTypes = await getApiDonationTypes();

    return {
      type: "SET_API_DONATION_TYPES",
      payload: apiDonationTypes,
    };
  };

export const getSetSelectedDonationTypeId = (
  selectedDonationTypeId: number
): SetSelectedDonationTypeId => ({
  type: "SET_SELECTED_DONATION_TYPE_ID",
  payload: selectedDonationTypeId,
});

export const getSetDonationAction = (donation: Donation): SetDonation => ({
  type: "SET_DONATION",
  payload: donation,
});
