import React, { useContext, useLayoutEffect, useRef } from "react";
import { Card, Form } from "react-bootstrap";
import { Context } from "./context/store";

import giftaidItImage from "./images/giftaid-it.png";

export const GiftaidIt: React.FC<{
  amount: number;
  isGiftaid: boolean;
  onChange: (boolean) => void;
}> = ({ amount, isGiftaid, onChange }) => {
  const {
    state: { donation },
  } = useContext(Context);

  const isGiftaidRef = useRef<HTMLInputElement | undefined>(undefined);

  useLayoutEffect(() => {
    if (isGiftaidRef.current && donation.isGiftaid)
      isGiftaidRef.current.checked = donation.isGiftaid;
  }, []);

  const fixedAmount = +amount.toFixed(2);
  const giftaidAmount = +(fixedAmount * 0.25).toFixed(2);
  const amountPlusGiftaid = fixedAmount + giftaidAmount;

  return (
    <Card>
      <Card.Body style={{ position: "relative" }}>
        <img
          className="float-end"
          src={giftaidItImage}
          style={{ height: 60, margin: "0 0 10px 10px" }}
        />

        <p className="lead">
          Are you a UK taxpayer making a personal donation?
        </p>
        <p>
          With Gift Aid, your donation of{" "}
          <strong className="text-primary">£{fixedAmount.toFixed(2)}</strong>{" "}
          would be worth{" "}
          <strong className="text-primary">
            £{amountPlusGiftaid.toFixed(2)}
          </strong>{" "}
          at no extra cost to you.
        </p>
        <p>
          You still pay{" "}
          <strong className="text-primary">£{fixedAmount.toFixed(2)}</strong>,
          but we reclaim the additional{" "}
          <strong className="text-primary">£{giftaidAmount.toFixed(2)}</strong>{" "}
          from the tax that you pay. Your tax payments can instead be used to
          increase the value of your donation and help IHRC Trust even more.
        </p>
        <Form.Check
          type="switch"
          label={
            <>
              <p>
                Yes, I am a UK taxpayer and would like IHRC Trust to reclaim tax
                on all donations I have made within the last four years and all
                donations that I make hereafter.
              </p>
              {isGiftaid && (
                <p>
                  I understand that if I pay less Income Tax and/or Capital
                  Gains Tax than the amount of Gift Aid claimed on all my
                  donations in that tax year it is my responsibility to pay any
                  difference. Please remember to notify IHRC Trust if you want
                  to cancel this declaration, change your name or home address
                  or no longer pay sufficient tax on your income and/or capital
                  gains.
                </p>
              )}
            </>
          }
          onChange={(e) => onChange(e.target.checked)}
          ref={isGiftaidRef}
        />
      </Card.Body>
    </Card>
  );
};
