import React, {
  HTMLAttributes,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { debounce } from "throttle-debounce";
import ContentLoader from "react-content-loader";

import { apiBaseUrl } from "../config";

export const useWindowResize = () => {
  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const debouncedHandleResize = debounce(1000, () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    });

    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  return dimensions;
};

// const ImageLoader = ({ width, height }: { width: number; height: number }) => (
//   <ContentLoader viewBox={`0 0 ${width} ${height}`} style={{ width, height }}>
//     <rect x="0" y="0" rx="3" ry="3" width={width} height={height} />
//   </ContentLoader>
// );

const waitForAllDocumentImagesToLoad = async () => {
  await Promise.all(
    Array.from(document.images)
      .filter((img) => !img.complete)
      .map(
        (img) =>
          new Promise((resolve) => {
            img.onload = img.onerror = resolve;
          })
      )
  );
};

export const DynamicImage: React.FC<
  { url: string; imageFocus?: string } & HTMLAttributes<HTMLDivElement>
> = ({ url, imageFocus, children, style, ...restProps }) => {
  const imageContainerRef = useRef<HTMLDivElement | undefined>(undefined);

  const dimensions = useWindowResize();

  const downloadingImage = new Image();

  useLayoutEffect(() => {
    if (!imageContainerRef) return;

    const { width, height } = dimensions;

    // Download low-res image first
    downloadingImage.onload = async () => {
      if (!imageContainerRef.current) return;

      imageContainerRef.current.style.backgroundImage = `url(${downloadingImage.src})`;

      // Then download full-res image
      downloadingImage.onload = () => {
        imageContainerRef.current.style.backgroundImage = `url(${downloadingImage.src})`;
      };

      await waitForAllDocumentImagesToLoad();

      downloadingImage.src = `${apiBaseUrl}/Image?width=${width}&height=${height}&url=${apiBaseUrl}${url}`;
    };

    downloadingImage.src = `${apiBaseUrl}/Image?quality=30&width=${width}&height=${height}&url=${apiBaseUrl}${url}`;
  }, [imageContainerRef, dimensions]);

  return (
    <div
      {...restProps}
      ref={imageContainerRef}
      style={{
        ...style,
        backgroundSize: "cover",
        backgroundPosition: (
          imageFocus?.split(/(?=[A-Z])/)?.map((x) => x.toLowerCase()) ?? [
            "center",
            "center",
          ]
        ).join(" "),
        // position: "relative",
      }}
    >
      {/* {!loaded && (
        <div
          style={{
            // position: "absolute",
            width: dimensions.width,
            height: dimensions.height,
          }}
        >
          <ImageLoader width={dimensions.width} height={dimensions.height} />
        </div>
      )} */}
      {children}
    </div>
  );
};
