export interface Donation {
  isMonthly?: boolean;
  isGiftaid?: boolean;
  donor?: {
    address?: Address;
  };
  country: string;
  state?: string;
  paymentMethod?: PaymentMethod;
  emailOptIn?: boolean;
  donationAmounts: DonationAmount[];
}

type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<
  infer ElementType
>
  ? ElementType
  : never;

export const intents = ["General", "Sadaqah", "Zakat"] as const;
export type Intent = ElementType<typeof intents>;

export type PaymentMethod = "CreditCard" | "Paypal";

export interface DonationAmount {
  id: string;
  donationTypeId: number;
  donationTypeGroup?: string;
  priceHandleId?: number;
  amount?: number;
  intent?: Intent;
}

export interface Address {
  firstName?: string;
  lastName?: string;
  email?: string;
  telephone?: string;
  address?: string;
  address2?: string;
  city?: string;
  postCode?: string;
}

export interface CountryAndState {
  country?: string;
  state?: string;
}

export const isValidAmount = (amount: number | undefined) => !!amount;

export const isValidIsMonthly = (isMonthly: boolean | undefined) =>
  isMonthly !== undefined;

export const isValidIntent = (intent: Intent | undefined) =>
  intent !== undefined;

export const isValidIsGiftaid = (isGiftaid: boolean | undefined) =>
  isGiftaid !== undefined;

export const isValidAddress = (
  country: string | undefined,
  state: string | undefined,
  address: Address | undefined
) => {
  const val =
    !!address?.firstName &&
    !!address?.lastName &&
    !!address?.email &&
    !!address?.telephone &&
    !!address?.address &&
    !!address?.city &&
    !!address?.postCode &&
    !!country &&
    (country !== "US" || !!state);

  return val;
};

export const isValidPaymentMethod = (
  paymentMethod: PaymentMethod | undefined
) => paymentMethod !== undefined;

export interface DonationDto {
  source: 1;

  isMonthly: boolean;
  isGiftaid: boolean;
  paymentMethod: PaymentMethod;

  donor?: {
    firstName?: string;
    lastName?: string;
    emailAddress?: string;
    telephone?: string;
    address?: string;
    address2?: string;
    city?: string;
    postCode?: string;
    country: string;
    state?: string;
    emailOptIn: boolean;
  };

  donationAmounts: DonationAmount[];
}
